import { Controller } from "@hotwired/stimulus";
import { formErrorMessage } from "$javascript/utilities/formErrorMessages";
import { track } from "../utilities/tracking";

export default class extends Controller {
  static classes = [ "invalid" ];

  validate(event) {
    const { currentStep } = event.detail;
    const visibleFields = currentStep.querySelectorAll(".rlx-form-control");

    if (!this.validateAllFields(visibleFields)) {
      event.preventDefault();
    }
  }

  validateForm(event) {
    const fields = this.element.querySelectorAll(".rlx-form-control");

    if (!this.validateAllFields(fields)) {
      event.preventDefault();
    }

    track("contactForm");
  }

  validateAllFields(fields) {
    return [...fields].every(field => {
      const valid = field.checkValidity();

      if ( valid ) {
        // Remove invalid field marker and/or error messages.
        field.closest(".rlx-form-field").classList.remove(this.invalidClass);
        field.closest(".rlx-form-field").querySelector(".rlx-form-field__error-message").innerText = "";
      } else {
        // Mark the field as invalid
        field.closest(".rlx-form-field").classList.add(this.invalidClass);

        // Appends the error messages to the form fields.
        const errorMessages = formErrorMessage(field).join(", ");
        field.closest(".rlx-form-field").querySelector(".rlx-form-field__error-message").innerText = errorMessages;
      }

      return valid;
    });
  }
}
